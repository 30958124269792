import Swiper from "swiper";
import { Navigation } from "swiper/modules";
// import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

const video = document.getElementById("myVideo");
const playButton = document.getElementById("playPauseButton");

playButton.addEventListener("click", togglePlay);

function togglePlay() {
	if (video.paused || video.ended) {
		video.play();
		playButton.style.display = "none";
	} else {
		video.pause();
		playButton.style.display = "flex";
	}
}

// Hide video controls
video.controls = false;

// Reset the video to the beginning and show replay button when it ends
video.addEventListener("ended", function () {
	video.currentTime = 0;
	playButton.style.display = "flex";
});

document.addEventListener("DOMContentLoaded", function () {
	var video = document.getElementById("myVideo");
	video.load();
});

// Function to validate the form
function validateForm(form) {
	const name = form.querySelector("#name").value;
	const email = form.querySelector("#email").value;
	const phone = form.querySelector("#phone").value;
	const tipAp = form.querySelector("[name='tip-ap']").value;
	const tc = form.querySelector("[name='tc']").checked;

	// Regular expression to validate email format
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	// Clear any previous error messages
	document.querySelector("#error-list").innerHTML = "";

	// Array to store error messages
	const errors = [];

	// Validation checks
	if (name === "") {
		errors.push("Te rog să introduci numele tău");
	}

	if (!email.match(emailRegex)) {
		errors.push("Te rog să introduci o adresă de email validă");
	}

	if (email === "") {
		errors.push("Te rog să introduci adresa ta de email");
	}

	if (phone === "") {
		errors.push("Te rog să introduci numărul tău de telefon");
	}

	if (tipAp === "-") {
		errors.push("Te rog să selectezi tipul Apartamentei");
	}

	if (!tc) {
		errors.push("Trebuie să accepți termenii și condițiile");
	}

	return errors; // Form is valid if errors.length === 0
}

// Add a submit event listener to the form
const forms = document.querySelectorAll("form");
forms.forEach((form) =>
	form.addEventListener("submit", function (event) {
		const errors = validateForm(form);
		console.log(errors);
		// Display error messages in the "error-list" div
		if (errors.length > 0) {
			console.log("not validated");

			const errorList = form.querySelector("#error-list");
			const errorListHTML = "<ul><li>" + errors.join("</li><li>") + "</li></ul>";
			errorList.innerHTML = errorListHTML;
			event.preventDefault(); // Prevent the form from submitting if it's not valid
			return false; // Form is not valid
		}
	})
);

// Add smooth scrolling behavior to anchor links with a 100px offset
document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
	anchor.addEventListener("click", function (e) {
		console.log("click");
		e.preventDefault();

		const targetId = this.getAttribute("href").substring(1);
		const targetElement = document.getElementById(targetId);

		if (targetElement) {
			// Scroll smoothly to the target element with a 100px offset
			targetElement.scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "nearest",
				offsetTop: 100,
			});
		}
	});
});

// JavaScript to toggle mobile menu
document.addEventListener("DOMContentLoaded", function () {
	const mobileMenuButton = document.getElementById("mobile-menu-button");
	const menuContainer = document.querySelector(".menu-items-container");

	mobileMenuButton.addEventListener("click", function () {
		menuContainer.classList.toggle("-translate-x-[500px]");
		menuContainer.classList.toggle("h-0");
	});

	const menuItems = document.querySelectorAll(".menu-items-container a").forEach((menuItem) =>
		menuItem.addEventListener("click", () => {
			menuContainer.classList.toggle("-translate-x-[500px]");
			menuContainer.classList.toggle("h-0");
		})
	);
});

// JavaScript for image sliders
document.addEventListener("DOMContentLoaded", function () {
	const closeGalleryButton = document.querySelector("#close-gallery");
	const swiperContainer = document.querySelector(".swiper-container");

	closeGalleryButton?.addEventListener("click", () => {
		swiperContainer?.classList.toggle("hidden");
	});

	const swiper = new Swiper(".swiper", {
		speed: 400,
		modules: [Navigation],
		spaceBetween: 100,

		// Navigation arrows
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});

	//filters
	const filterButtons = document.querySelectorAll("[data-filter].button-trigger");

	// Add click event listeners to filter buttons
	filterButtons.forEach((button) => {
		button.addEventListener("click", function () {
			const filterValue = this.getAttribute("data-filter");

			console.log(filterValue);

			// Show/hide slides based on the filter value
			const slides = document.querySelectorAll(".swiper-slide");
			slides.forEach((slide) => {
				const slideFilter = slide.getAttribute("data-filter");
				if (filterValue === "all" || filterValue === slideFilter) {
					slide.style.display = "block";
				} else {
					slide.style.display = "none";
				}
			});

			// Update the Swiper instance (you may need to destroy and reinitialize it)
			swiper.update();
			swiperContainer?.classList.toggle("hidden");
		});
	});
});
